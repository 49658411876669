/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientCompany } from '../model';
// @ts-ignore
import { ClientCompanyDetail } from '../model';
// @ts-ignore
import { ClientCompanyRegister } from '../model';
// @ts-ignore
import { ClientUserAdd } from '../model';
// @ts-ignore
import { ClientUserDelete } from '../model';
// @ts-ignore
import { ClientUserRegister } from '../model';
// @ts-ignore
import { ClientUserUpdateRole } from '../model';
// @ts-ignore
import { PatchedClientCompanyDetail } from '../model';
// @ts-ignore
import { StorageSize } from '../model';
/**
 * ClientCompanyApi - axios parameter creator
 * @export
 */
export const ClientCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyActiveCreate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyActiveCreate.');
            }
            const localVarPath = `/api/client_company/{id}/active/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主管理者が広告主ユーザーを追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主ユーザーに登録ページのURLが送信される。   詳細は`ClientUserAddSerializer`を参照すること。
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserAdd} clientUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyAddUserCreate: async (id: string, clientUserAdd: ClientUserAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyAddUserCreate.');
            }
            // verify required parameter 'clientUserAdd' is not null or undefined
            if (clientUserAdd === null || clientUserAdd === undefined) {
                throw new RequiredError('clientUserAdd','Required parameter clientUserAdd was null or undefined when calling clientCompanyAddUserCreate.');
            }
            const localVarPath = `/api/client_company/{id}/add_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientUserAdd !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientUserAdd !== undefined ? clientUserAdd : {})
                : (clientUserAdd || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * adlog従業員が広告主企業を追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主に登録ページのURLが送信される。   詳細は`ClientCompanyRegisterSerializer`を参照すること。
         * @param {ClientCompanyRegister} clientCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyCreate: async (clientCompanyRegister: ClientCompanyRegister, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCompanyRegister' is not null or undefined
            if (clientCompanyRegister === null || clientCompanyRegister === undefined) {
                throw new RequiredError('clientCompanyRegister','Required parameter clientCompanyRegister was null or undefined when calling clientCompanyCreate.');
            }
            const localVarPath = `/api/client_company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientCompanyRegister !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientCompanyRegister !== undefined ? clientCompanyRegister : {})
                : (clientCompanyRegister || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserDelete} clientUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyDeleteUserCreate: async (id: string, clientUserDelete: ClientUserDelete, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyDeleteUserCreate.');
            }
            // verify required parameter 'clientUserDelete' is not null or undefined
            if (clientUserDelete === null || clientUserDelete === undefined) {
                throw new RequiredError('clientUserDelete','Required parameter clientUserDelete was null or undefined when calling clientCompanyDeleteUserCreate.');
            }
            const localVarPath = `/api/client_company/{id}/delete_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientUserDelete !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientUserDelete !== undefined ? clientUserDelete : {})
                : (clientUserDelete || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyDestroy: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyDestroy.');
            }
            const localVarPath = `/api/client_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyExpandStorageCreate: async (id: string, storageSize: StorageSize, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyExpandStorageCreate.');
            }
            // verify required parameter 'storageSize' is not null or undefined
            if (storageSize === null || storageSize === undefined) {
                throw new RequiredError('storageSize','Required parameter storageSize was null or undefined when calling clientCompanyExpandStorageCreate.');
            }
            const localVarPath = `/api/client_company/{id}/expand_storage/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof storageSize !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(storageSize !== undefined ? storageSize : {})
                : (storageSize || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyFreezeCreate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyFreezeCreate.');
            }
            const localVarPath = `/api/client_company/{id}/freeze/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client_company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {PatchedClientCompanyDetail} [patchedClientCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyPartialUpdate: async (id: string, patchedClientCompanyDetail?: PatchedClientCompanyDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyPartialUpdate.');
            }
            const localVarPath = `/api/client_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof patchedClientCompanyDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(patchedClientCompanyDetail !== undefined ? patchedClientCompanyDetail : {})
                : (patchedClientCompanyDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyReduceStorageCreate: async (id: string, storageSize: StorageSize, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyReduceStorageCreate.');
            }
            // verify required parameter 'storageSize' is not null or undefined
            if (storageSize === null || storageSize === undefined) {
                throw new RequiredError('storageSize','Required parameter storageSize was null or undefined when calling clientCompanyReduceStorageCreate.');
            }
            const localVarPath = `/api/client_company/{id}/reduce_storage/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof storageSize !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(storageSize !== undefined ? storageSize : {})
                : (storageSize || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRegisterCreate: async (eventId: string, clientCompanyDetail: ClientCompanyDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling clientCompanyRegisterCreate.');
            }
            // verify required parameter 'clientCompanyDetail' is not null or undefined
            if (clientCompanyDetail === null || clientCompanyDetail === undefined) {
                throw new RequiredError('clientCompanyDetail','Required parameter clientCompanyDetail was null or undefined when calling clientCompanyRegisterCreate.');
            }
            const localVarPath = `/api/client_company/register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientCompanyDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientCompanyDetail !== undefined ? clientCompanyDetail : {})
                : (clientCompanyDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRegisterRetrieve: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling clientCompanyRegisterRetrieve.');
            }
            const localVarPath = `/api/client_company/register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyRetrieve.');
            }
            const localVarPath = `/api/client_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUpdate: async (id: string, clientCompanyDetail: ClientCompanyDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyUpdate.');
            }
            // verify required parameter 'clientCompanyDetail' is not null or undefined
            if (clientCompanyDetail === null || clientCompanyDetail === undefined) {
                throw new RequiredError('clientCompanyDetail','Required parameter clientCompanyDetail was null or undefined when calling clientCompanyUpdate.');
            }
            const localVarPath = `/api/client_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientCompanyDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientCompanyDetail !== undefined ? clientCompanyDetail : {})
                : (clientCompanyDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserUpdateRole} clientUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUpdateUserRoleCreate: async (id: string, clientUserUpdateRole: ClientUserUpdateRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling clientCompanyUpdateUserRoleCreate.');
            }
            // verify required parameter 'clientUserUpdateRole' is not null or undefined
            if (clientUserUpdateRole === null || clientUserUpdateRole === undefined) {
                throw new RequiredError('clientUserUpdateRole','Required parameter clientUserUpdateRole was null or undefined when calling clientCompanyUpdateUserRoleCreate.');
            }
            const localVarPath = `/api/client_company/{id}/update_user_role/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientUserUpdateRole !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientUserUpdateRole !== undefined ? clientUserUpdateRole : {})
                : (clientUserUpdateRole || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientUserRegister} clientUserRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUserRegisterCreate: async (eventId: string, clientUserRegister: ClientUserRegister, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling clientCompanyUserRegisterCreate.');
            }
            // verify required parameter 'clientUserRegister' is not null or undefined
            if (clientUserRegister === null || clientUserRegister === undefined) {
                throw new RequiredError('clientUserRegister','Required parameter clientUserRegister was null or undefined when calling clientCompanyUserRegisterCreate.');
            }
            const localVarPath = `/api/client_company/user_register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientUserRegister !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientUserRegister !== undefined ? clientUserRegister : {})
                : (clientUserRegister || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUserRegisterRetrieve: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling clientCompanyUserRegisterRetrieve.');
            }
            const localVarPath = `/api/client_company/user_register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientCompanyApi - functional programming interface
 * @export
 */
export const ClientCompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyActiveCreate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyActiveCreate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主管理者が広告主ユーザーを追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主ユーザーに登録ページのURLが送信される。   詳細は`ClientUserAddSerializer`を参照すること。
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserAdd} clientUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyAddUserCreate(id: string, clientUserAdd: ClientUserAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserAdd>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyAddUserCreate(id, clientUserAdd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * adlog従業員が広告主企業を追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主に登録ページのURLが送信される。   詳細は`ClientCompanyRegisterSerializer`を参照すること。
         * @param {ClientCompanyRegister} clientCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyCreate(clientCompanyRegister: ClientCompanyRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyRegister>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyCreate(clientCompanyRegister, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserDelete} clientUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyDeleteUserCreate(id: string, clientUserDelete: ClientUserDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserDelete>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyDeleteUserCreate(id, clientUserDelete, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyDestroy(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyDestroy(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyExpandStorageCreate(id: string, storageSize: StorageSize, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageSize>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyExpandStorageCreate(id, storageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyFreezeCreate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyFreezeCreate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientCompany>>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {PatchedClientCompanyDetail} [patchedClientCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyPartialUpdate(id: string, patchedClientCompanyDetail?: PatchedClientCompanyDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyDetail>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyPartialUpdate(id, patchedClientCompanyDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyReduceStorageCreate(id: string, storageSize: StorageSize, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageSize>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyReduceStorageCreate(id, storageSize, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyRegisterCreate(eventId: string, clientCompanyDetail: ClientCompanyDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyDetail>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyRegisterCreate(eventId, clientCompanyDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyRegisterRetrieve(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyDetail>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyRegisterRetrieve(eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyDetail>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyUpdate(id: string, clientCompanyDetail: ClientCompanyDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCompanyDetail>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyUpdate(id, clientCompanyDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserUpdateRole} clientUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyUpdateUserRoleCreate(id: string, clientUserUpdateRole: ClientUserUpdateRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserUpdateRole>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyUpdateUserRoleCreate(id, clientUserUpdateRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientUserRegister} clientUserRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyUserRegisterCreate(eventId: string, clientUserRegister: ClientUserRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyUserRegisterCreate(eventId, clientUserRegister, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCompanyUserRegisterRetrieve(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserRegister>> {
            const localVarAxiosArgs = await ClientCompanyApiAxiosParamCreator(configuration).clientCompanyUserRegisterRetrieve(eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientCompanyApi - factory interface
 * @export
 */
export const ClientCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyActiveCreate(id: string, options?: any): AxiosPromise<void> {
            return ClientCompanyApiFp(configuration).clientCompanyActiveCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主管理者が広告主ユーザーを追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主ユーザーに登録ページのURLが送信される。   詳細は`ClientUserAddSerializer`を参照すること。
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserAdd} clientUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyAddUserCreate(id: string, clientUserAdd: ClientUserAdd, options?: any): AxiosPromise<ClientUserAdd> {
            return ClientCompanyApiFp(configuration).clientCompanyAddUserCreate(id, clientUserAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * adlog従業員が広告主企業を追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主に登録ページのURLが送信される。   詳細は`ClientCompanyRegisterSerializer`を参照すること。
         * @param {ClientCompanyRegister} clientCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyCreate(clientCompanyRegister: ClientCompanyRegister, options?: any): AxiosPromise<ClientCompanyRegister> {
            return ClientCompanyApiFp(configuration).clientCompanyCreate(clientCompanyRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserDelete} clientUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyDeleteUserCreate(id: string, clientUserDelete: ClientUserDelete, options?: any): AxiosPromise<ClientUserDelete> {
            return ClientCompanyApiFp(configuration).clientCompanyDeleteUserCreate(id, clientUserDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyDestroy(id: string, options?: any): AxiosPromise<void> {
            return ClientCompanyApiFp(configuration).clientCompanyDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyExpandStorageCreate(id: string, storageSize: StorageSize, options?: any): AxiosPromise<StorageSize> {
            return ClientCompanyApiFp(configuration).clientCompanyExpandStorageCreate(id, storageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyFreezeCreate(id: string, options?: any): AxiosPromise<void> {
            return ClientCompanyApiFp(configuration).clientCompanyFreezeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyList(options?: any): AxiosPromise<Array<ClientCompany>> {
            return ClientCompanyApiFp(configuration).clientCompanyList(options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {PatchedClientCompanyDetail} [patchedClientCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyPartialUpdate(id: string, patchedClientCompanyDetail?: PatchedClientCompanyDetail, options?: any): AxiosPromise<ClientCompanyDetail> {
            return ClientCompanyApiFp(configuration).clientCompanyPartialUpdate(id, patchedClientCompanyDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {StorageSize} storageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyReduceStorageCreate(id: string, storageSize: StorageSize, options?: any): AxiosPromise<StorageSize> {
            return ClientCompanyApiFp(configuration).clientCompanyReduceStorageCreate(id, storageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRegisterCreate(eventId: string, clientCompanyDetail: ClientCompanyDetail, options?: any): AxiosPromise<ClientCompanyDetail> {
            return ClientCompanyApiFp(configuration).clientCompanyRegisterCreate(eventId, clientCompanyDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業が新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRegisterRetrieve(eventId: string, options?: any): AxiosPromise<ClientCompanyDetail> {
            return ClientCompanyApiFp(configuration).clientCompanyRegisterRetrieve(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyRetrieve(id: string, options?: any): AxiosPromise<ClientCompanyDetail> {
            return ClientCompanyApiFp(configuration).clientCompanyRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientCompanyDetail} clientCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUpdate(id: string, clientCompanyDetail: ClientCompanyDetail, options?: any): AxiosPromise<ClientCompanyDetail> {
            return ClientCompanyApiFp(configuration).clientCompanyUpdate(id, clientCompanyDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主企業のAPI
         * @param {string} id A UUID string identifying this client company.
         * @param {ClientUserUpdateRole} clientUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUpdateUserRoleCreate(id: string, clientUserUpdateRole: ClientUserUpdateRole, options?: any): AxiosPromise<ClientUserUpdateRole> {
            return ClientCompanyApiFp(configuration).clientCompanyUpdateUserRoleCreate(id, clientUserUpdateRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {ClientUserRegister} clientUserRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUserRegisterCreate(eventId: string, clientUserRegister: ClientUserRegister, options?: any): AxiosPromise<void> {
            return ClientCompanyApiFp(configuration).clientCompanyUserRegisterCreate(eventId, clientUserRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 広告主ユーザーが新規登録するときに利用するAPI
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCompanyUserRegisterRetrieve(eventId: string, options?: any): AxiosPromise<ClientUserRegister> {
            return ClientCompanyApiFp(configuration).clientCompanyUserRegisterRetrieve(eventId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientCompanyApi - object-oriented interface
 * @export
 * @class ClientCompanyApi
 * @extends {BaseAPI}
 */
export class ClientCompanyApi extends BaseAPI {
    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyActiveCreate(id: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyActiveCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主管理者が広告主ユーザーを追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主ユーザーに登録ページのURLが送信される。   詳細は`ClientUserAddSerializer`を参照すること。
     * @param {string} id A UUID string identifying this client company.
     * @param {ClientUserAdd} clientUserAdd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyAddUserCreate(id: string, clientUserAdd: ClientUserAdd, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyAddUserCreate(id, clientUserAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adlog従業員が広告主企業を追加する際に利用するAPI  このAPIにPOSTすると、CompanyEventが作成され、広告主に登録ページのURLが送信される。   詳細は`ClientCompanyRegisterSerializer`を参照すること。
     * @param {ClientCompanyRegister} clientCompanyRegister 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyCreate(clientCompanyRegister: ClientCompanyRegister, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyCreate(clientCompanyRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {ClientUserDelete} clientUserDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyDeleteUserCreate(id: string, clientUserDelete: ClientUserDelete, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyDeleteUserCreate(id, clientUserDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyDestroy(id: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {StorageSize} storageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyExpandStorageCreate(id: string, storageSize: StorageSize, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyExpandStorageCreate(id, storageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyFreezeCreate(id: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyFreezeCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyList(options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {PatchedClientCompanyDetail} [patchedClientCompanyDetail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyPartialUpdate(id: string, patchedClientCompanyDetail?: PatchedClientCompanyDetail, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyPartialUpdate(id, patchedClientCompanyDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {StorageSize} storageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyReduceStorageCreate(id: string, storageSize: StorageSize, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyReduceStorageCreate(id, storageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業が新規登録するときに利用するAPI
     * @param {string} eventId Register Event ID
     * @param {ClientCompanyDetail} clientCompanyDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyRegisterCreate(eventId: string, clientCompanyDetail: ClientCompanyDetail, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyRegisterCreate(eventId, clientCompanyDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業が新規登録するときに利用するAPI
     * @param {string} eventId Register Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyRegisterRetrieve(eventId: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyRegisterRetrieve(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyRetrieve(id: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {ClientCompanyDetail} clientCompanyDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyUpdate(id: string, clientCompanyDetail: ClientCompanyDetail, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyUpdate(id, clientCompanyDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主企業のAPI
     * @param {string} id A UUID string identifying this client company.
     * @param {ClientUserUpdateRole} clientUserUpdateRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyUpdateUserRoleCreate(id: string, clientUserUpdateRole: ClientUserUpdateRole, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyUpdateUserRoleCreate(id, clientUserUpdateRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主ユーザーが新規登録するときに利用するAPI
     * @param {string} eventId Register Event ID
     * @param {ClientUserRegister} clientUserRegister 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyUserRegisterCreate(eventId: string, clientUserRegister: ClientUserRegister, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyUserRegisterCreate(eventId, clientUserRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 広告主ユーザーが新規登録するときに利用するAPI
     * @param {string} eventId Register Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCompanyApi
     */
    public clientCompanyUserRegisterRetrieve(eventId: string, options?: any) {
        return ClientCompanyApiFp(this.configuration).clientCompanyUserRegisterRetrieve(eventId, options).then((request) => request(this.axios, this.basePath));
    }
}
