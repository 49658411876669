/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SystemCompany } from '../model';
// @ts-ignore
import { SystemUserAdd } from '../model';
// @ts-ignore
import { SystemUserDelete } from '../model';
// @ts-ignore
import { SystemUserUpdateRole } from '../model';
/**
 * SystemCompanyApi - axios parameter creator
 * @export
 */
export const SystemCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * システム管理企業(adlog)の従業員追加
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserAdd} systemUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyAddUserCreate: async (id: string, systemUserAdd: SystemUserAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling systemCompanyAddUserCreate.');
            }
            // verify required parameter 'systemUserAdd' is not null or undefined
            if (systemUserAdd === null || systemUserAdd === undefined) {
                throw new RequiredError('systemUserAdd','Required parameter systemUserAdd was null or undefined when calling systemCompanyAddUserCreate.');
            }
            const localVarPath = `/api/system_company/{id}/add_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof systemUserAdd !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(systemUserAdd !== undefined ? systemUserAdd : {})
                : (systemUserAdd || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * システム管理企業(adlog)の従業員削除
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserDelete} systemUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyDeleteUserCreate: async (id: string, systemUserDelete: SystemUserDelete, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling systemCompanyDeleteUserCreate.');
            }
            // verify required parameter 'systemUserDelete' is not null or undefined
            if (systemUserDelete === null || systemUserDelete === undefined) {
                throw new RequiredError('systemUserDelete','Required parameter systemUserDelete was null or undefined when calling systemCompanyDeleteUserCreate.');
            }
            const localVarPath = `/api/system_company/{id}/delete_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof systemUserDelete !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(systemUserDelete !== undefined ? systemUserDelete : {})
                : (systemUserDelete || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * システム管理企業(adlog)の取得
         * @param {string} id A UUID string identifying this system company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling systemCompanyRetrieve.');
            }
            const localVarPath = `/api/system_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * システム管理企業(adlog)の従業員の権限変更
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserUpdateRole} systemUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyUpdateUserRoleCreate: async (id: string, systemUserUpdateRole: SystemUserUpdateRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling systemCompanyUpdateUserRoleCreate.');
            }
            // verify required parameter 'systemUserUpdateRole' is not null or undefined
            if (systemUserUpdateRole === null || systemUserUpdateRole === undefined) {
                throw new RequiredError('systemUserUpdateRole','Required parameter systemUserUpdateRole was null or undefined when calling systemCompanyUpdateUserRoleCreate.');
            }
            const localVarPath = `/api/system_company/{id}/update_user_role/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof systemUserUpdateRole !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(systemUserUpdateRole !== undefined ? systemUserUpdateRole : {})
                : (systemUserUpdateRole || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemCompanyApi - functional programming interface
 * @export
 */
export const SystemCompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * システム管理企業(adlog)の従業員追加
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserAdd} systemUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemCompanyAddUserCreate(id: string, systemUserAdd: SystemUserAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemUserAdd>> {
            const localVarAxiosArgs = await SystemCompanyApiAxiosParamCreator(configuration).systemCompanyAddUserCreate(id, systemUserAdd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * システム管理企業(adlog)の従業員削除
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserDelete} systemUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemCompanyDeleteUserCreate(id: string, systemUserDelete: SystemUserDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemUserDelete>> {
            const localVarAxiosArgs = await SystemCompanyApiAxiosParamCreator(configuration).systemCompanyDeleteUserCreate(id, systemUserDelete, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * システム管理企業(adlog)の取得
         * @param {string} id A UUID string identifying this system company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemCompanyRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemCompany>> {
            const localVarAxiosArgs = await SystemCompanyApiAxiosParamCreator(configuration).systemCompanyRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * システム管理企業(adlog)の従業員の権限変更
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserUpdateRole} systemUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemCompanyUpdateUserRoleCreate(id: string, systemUserUpdateRole: SystemUserUpdateRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemUserUpdateRole>> {
            const localVarAxiosArgs = await SystemCompanyApiAxiosParamCreator(configuration).systemCompanyUpdateUserRoleCreate(id, systemUserUpdateRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemCompanyApi - factory interface
 * @export
 */
export const SystemCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * システム管理企業(adlog)の従業員追加
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserAdd} systemUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyAddUserCreate(id: string, systemUserAdd: SystemUserAdd, options?: any): AxiosPromise<SystemUserAdd> {
            return SystemCompanyApiFp(configuration).systemCompanyAddUserCreate(id, systemUserAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * システム管理企業(adlog)の従業員削除
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserDelete} systemUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyDeleteUserCreate(id: string, systemUserDelete: SystemUserDelete, options?: any): AxiosPromise<SystemUserDelete> {
            return SystemCompanyApiFp(configuration).systemCompanyDeleteUserCreate(id, systemUserDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * システム管理企業(adlog)の取得
         * @param {string} id A UUID string identifying this system company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyRetrieve(id: string, options?: any): AxiosPromise<SystemCompany> {
            return SystemCompanyApiFp(configuration).systemCompanyRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * システム管理企業(adlog)の従業員の権限変更
         * @param {string} id A UUID string identifying this system company.
         * @param {SystemUserUpdateRole} systemUserUpdateRole 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemCompanyUpdateUserRoleCreate(id: string, systemUserUpdateRole: SystemUserUpdateRole, options?: any): AxiosPromise<SystemUserUpdateRole> {
            return SystemCompanyApiFp(configuration).systemCompanyUpdateUserRoleCreate(id, systemUserUpdateRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemCompanyApi - object-oriented interface
 * @export
 * @class SystemCompanyApi
 * @extends {BaseAPI}
 */
export class SystemCompanyApi extends BaseAPI {
    /**
     * システム管理企業(adlog)の従業員追加
     * @param {string} id A UUID string identifying this system company.
     * @param {SystemUserAdd} systemUserAdd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemCompanyApi
     */
    public systemCompanyAddUserCreate(id: string, systemUserAdd: SystemUserAdd, options?: any) {
        return SystemCompanyApiFp(this.configuration).systemCompanyAddUserCreate(id, systemUserAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * システム管理企業(adlog)の従業員削除
     * @param {string} id A UUID string identifying this system company.
     * @param {SystemUserDelete} systemUserDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemCompanyApi
     */
    public systemCompanyDeleteUserCreate(id: string, systemUserDelete: SystemUserDelete, options?: any) {
        return SystemCompanyApiFp(this.configuration).systemCompanyDeleteUserCreate(id, systemUserDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * システム管理企業(adlog)の取得
     * @param {string} id A UUID string identifying this system company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemCompanyApi
     */
    public systemCompanyRetrieve(id: string, options?: any) {
        return SystemCompanyApiFp(this.configuration).systemCompanyRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * システム管理企業(adlog)の従業員の権限変更
     * @param {string} id A UUID string identifying this system company.
     * @param {SystemUserUpdateRole} systemUserUpdateRole 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemCompanyApi
     */
    public systemCompanyUpdateUserRoleCreate(id: string, systemUserUpdateRole: SystemUserUpdateRole, options?: any) {
        return SystemCompanyApiFp(this.configuration).systemCompanyUpdateUserRoleCreate(id, systemUserUpdateRole, options).then((request) => request(this.axios, this.basePath));
    }
}
