/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SimpleJWTRefreshResponse } from '../model';
// @ts-ignore
import { SimpleJWTResponse } from '../model';
// @ts-ignore
import { TokenObtainPair } from '../model';
// @ts-ignore
import { TokenRefresh } from '../model';
// @ts-ignore
import { TokenVerify } from '../model';
/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate: async (tokenObtainPair: TokenObtainPair, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenObtainPair' is not null or undefined
            if (tokenObtainPair === null || tokenObtainPair === undefined) {
                throw new RequiredError('tokenObtainPair','Required parameter tokenObtainPair was null or undefined when calling tokenCreate.');
            }
            const localVarPath = `/api/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tokenObtainPair !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tokenObtainPair !== undefined ? tokenObtainPair : {})
                : (tokenObtainPair || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate: async (tokenRefresh: TokenRefresh, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefresh' is not null or undefined
            if (tokenRefresh === null || tokenRefresh === undefined) {
                throw new RequiredError('tokenRefresh','Required parameter tokenRefresh was null or undefined when calling tokenRefreshCreate.');
            }
            const localVarPath = `/api/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tokenRefresh !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tokenRefresh !== undefined ? tokenRefresh : {})
                : (tokenRefresh || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} tokenVerify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenVerifyCreate: async (tokenVerify: TokenVerify, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenVerify' is not null or undefined
            if (tokenVerify === null || tokenVerify === undefined) {
                throw new RequiredError('tokenVerify','Required parameter tokenVerify was null or undefined when calling tokenVerifyCreate.');
            }
            const localVarPath = `/api/token/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tokenVerify !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tokenVerify !== undefined ? tokenVerify : {})
                : (tokenVerify || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenCreate(tokenObtainPair: TokenObtainPair, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleJWTResponse>> {
            const localVarAxiosArgs = await TokenApiAxiosParamCreator(configuration).tokenCreate(tokenObtainPair, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleJWTRefreshResponse>> {
            const localVarAxiosArgs = await TokenApiAxiosParamCreator(configuration).tokenRefreshCreate(tokenRefresh, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} tokenVerify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenVerifyCreate(tokenVerify: TokenVerify, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenVerify>> {
            const localVarAxiosArgs = await TokenApiAxiosParamCreator(configuration).tokenVerifyCreate(tokenVerify, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCreate(tokenObtainPair: TokenObtainPair, options?: any): AxiosPromise<SimpleJWTResponse> {
            return TokenApiFp(configuration).tokenCreate(tokenObtainPair, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any): AxiosPromise<SimpleJWTRefreshResponse> {
            return TokenApiFp(configuration).tokenRefreshCreate(tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
         * @param {TokenVerify} tokenVerify 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenVerifyCreate(tokenVerify: TokenVerify, options?: any): AxiosPromise<TokenVerify> {
            return TokenApiFp(configuration).tokenVerifyCreate(tokenVerify, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {TokenObtainPair} tokenObtainPair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenCreate(tokenObtainPair: TokenObtainPair, options?: any) {
        return TokenApiFp(this.configuration).tokenCreate(tokenObtainPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenRefreshCreate(tokenRefresh: TokenRefresh, options?: any) {
        return TokenApiFp(this.configuration).tokenRefreshCreate(tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public tokenVerifyCreate(tokenVerify: TokenVerify, options?: any) {
        return TokenApiFp(this.configuration).tokenVerifyCreate(tokenVerify, options).then((request) => request(this.axios, this.basePath));
    }
}
