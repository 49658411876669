/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum EventTypeEnum {
    RegisterClientCompany = 'register_client_company',
    RegisterClientUser = 'register_client_user',
    RegisterAgentCompanyPayerClient = 'register_agent_company_payer_client',
    RegisterAgentCompanyPayerAgent = 'register_agent_company_payer_agent',
    RegisterAgentUser = 'register_agent_user',
    ChangePayerToClient = 'change_payer_to_client',
    ChangePayerToAgent = 'change_payer_to_agent'
}



