/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PasswordChange } from '../model';
// @ts-ignore
import { PasswordReset } from '../model';
// @ts-ignore
import { PasswordResetConfirm } from '../model';
/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangeCreate: async (passwordChange: PasswordChange, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChange' is not null or undefined
            if (passwordChange === null || passwordChange === undefined) {
                throw new RequiredError('passwordChange','Required parameter passwordChange was null or undefined when calling passwordChangeCreate.');
            }
            const localVarPath = `/api/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passwordChange !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passwordChange !== undefined ? passwordChange : {})
                : (passwordChange || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password. Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetConfirmCreate: async (passwordResetConfirm: PasswordResetConfirm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetConfirm' is not null or undefined
            if (passwordResetConfirm === null || passwordResetConfirm === undefined) {
                throw new RequiredError('passwordResetConfirm','Required parameter passwordResetConfirm was null or undefined when calling passwordResetConfirmCreate.');
            }
            const localVarPath = `/api/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passwordResetConfirm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passwordResetConfirm !== undefined ? passwordResetConfirm : {})
                : (passwordResetConfirm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetCreate: async (passwordReset: PasswordReset, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordReset' is not null or undefined
            if (passwordReset === null || passwordReset === undefined) {
                throw new RequiredError('passwordReset','Required parameter passwordReset was null or undefined when calling passwordResetCreate.');
            }
            const localVarPath = `/api/password/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof passwordReset !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(passwordReset !== undefined ? passwordReset : {})
                : (passwordReset || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangeCreate(passwordChange: PasswordChange, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChange>> {
            const localVarAxiosArgs = await PasswordApiAxiosParamCreator(configuration).passwordChangeCreate(passwordChange, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password. Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetConfirm>> {
            const localVarAxiosArgs = await PasswordApiAxiosParamCreator(configuration).passwordResetConfirmCreate(passwordResetConfirm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetCreate(passwordReset: PasswordReset, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordReset>> {
            const localVarAxiosArgs = await PasswordApiAxiosParamCreator(configuration).passwordResetCreate(passwordReset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangeCreate(passwordChange: PasswordChange, options?: any): AxiosPromise<PasswordChange> {
            return PasswordApiFp(configuration).passwordChangeCreate(passwordChange, options).then((request) => request(axios, basePath));
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password. Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: any): AxiosPromise<PasswordResetConfirm> {
            return PasswordApiFp(configuration).passwordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
         * @param {PasswordReset} passwordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetCreate(passwordReset: PasswordReset, options?: any): AxiosPromise<PasswordReset> {
            return PasswordApiFp(configuration).passwordResetCreate(passwordReset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordChangeCreate(passwordChange: PasswordChange, options?: any) {
        return PasswordApiFp(this.configuration).passwordChangeCreate(passwordChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password. Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: any) {
        return PasswordApiFp(this.configuration).passwordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordReset} passwordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordResetCreate(passwordReset: PasswordReset, options?: any) {
        return PasswordApiFp(this.configuration).passwordResetCreate(passwordReset, options).then((request) => request(this.axios, this.basePath));
    }
}
