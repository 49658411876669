/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TrafficMaster } from '../model';
/**
 * TrafficMasterApi - axios parameter creator
 * @export
 */
export const TrafficMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [area] area
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [lineSet] line_set
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {string} [type] type
         * @param {string} [updatedAt] updated_at
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trafficMasterList: async (area?: string, company?: string, createdAt?: string, distinct?: string, id?: string, lineSet?: string, ordering?: string, search?: string, type?: string, updatedAt?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/traffic_master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = createdAt;
            }

            if (distinct !== undefined) {
                localVarQueryParameter['distinct'] = distinct;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lineSet !== undefined) {
                localVarQueryParameter['line_set'] = lineSet;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = updatedAt;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this traffic master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trafficMasterRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling trafficMasterRetrieve.');
            }
            const localVarPath = `/api/traffic_master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrafficMasterApi - functional programming interface
 * @export
 */
export const TrafficMasterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [area] area
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [lineSet] line_set
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {string} [type] type
         * @param {string} [updatedAt] updated_at
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trafficMasterList(area?: string, company?: string, createdAt?: string, distinct?: string, id?: string, lineSet?: string, ordering?: string, search?: string, type?: string, updatedAt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrafficMaster>>> {
            const localVarAxiosArgs = await TrafficMasterApiAxiosParamCreator(configuration).trafficMasterList(area, company, createdAt, distinct, id, lineSet, ordering, search, type, updatedAt, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this traffic master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trafficMasterRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficMaster>> {
            const localVarAxiosArgs = await TrafficMasterApiAxiosParamCreator(configuration).trafficMasterRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrafficMasterApi - factory interface
 * @export
 */
export const TrafficMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [area] area
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [lineSet] line_set
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {string} [type] type
         * @param {string} [updatedAt] updated_at
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trafficMasterList(area?: string, company?: string, createdAt?: string, distinct?: string, id?: string, lineSet?: string, ordering?: string, search?: string, type?: string, updatedAt?: string, options?: any): AxiosPromise<Array<TrafficMaster>> {
            return TrafficMasterApiFp(configuration).trafficMasterList(area, company, createdAt, distinct, id, lineSet, ordering, search, type, updatedAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this traffic master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trafficMasterRetrieve(id: string, options?: any): AxiosPromise<TrafficMaster> {
            return TrafficMasterApiFp(configuration).trafficMasterRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrafficMasterApi - object-oriented interface
 * @export
 * @class TrafficMasterApi
 * @extends {BaseAPI}
 */
export class TrafficMasterApi extends BaseAPI {
    /**
     * 
     * @param {string} [area] area
     * @param {string} [company] company
     * @param {string} [createdAt] created_at
     * @param {string} [distinct] Distinct QuerySet
     * @param {string} [id] id
     * @param {string} [lineSet] line_set
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {string} [type] type
     * @param {string} [updatedAt] updated_at
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficMasterApi
     */
    public trafficMasterList(area?: string, company?: string, createdAt?: string, distinct?: string, id?: string, lineSet?: string, ordering?: string, search?: string, type?: string, updatedAt?: string, options?: any) {
        return TrafficMasterApiFp(this.configuration).trafficMasterList(area, company, createdAt, distinct, id, lineSet, ordering, search, type, updatedAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this traffic master.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrafficMasterApi
     */
    public trafficMasterRetrieve(id: string, options?: any) {
        return TrafficMasterApiFp(this.configuration).trafficMasterRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}
