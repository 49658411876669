/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleEnum {
    SystemMaster = 'system_master',
    SystemAdmin = 'system_admin',
    SystemUser = 'system_user',
    ClientOwner = 'client_owner',
    ClientAdmin = 'client_admin',
    ClientUser = 'client_user',
    AgentUser = 'agent_user'
}



