/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContentFile } from '../model';
/**
 * ContentFilesApi - axios parameter creator
 * @export
 */
export const ContentFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesCreate: async (name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling contentFilesCreate.');
            }
            // verify required parameter 'fileOwner' is not null or undefined
            if (fileOwner === null || fileOwner === undefined) {
                throw new RequiredError('fileOwner','Required parameter fileOwner was null or undefined when calling contentFilesCreate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling contentFilesCreate.');
            }
            const localVarPath = `/api/content_files/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (fileOwner !== undefined) { 
                localVarFormParams.append('file_owner', fileOwner as any);
            }
    
            if (agentCompany !== undefined) { 
                localVarFormParams.append('agent_company', agentCompany as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (pdfFile !== undefined) { 
                localVarFormParams.append('pdf_file', pdfFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesDestroy: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contentFilesDestroy.');
            }
            const localVarPath = `/api/content_files/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [agentCompany] agent_company
         * @param {string} [createdAt] created_at
         * @param {string} [fileOwner] file_owner
         * @param {string} [fileSize] file_size
         * @param {string} [id] id
         * @param {string} [idIn] id__in
         * @param {string} [mimeType] mime_type
         * @param {string} [name] name
         * @param {string} [pdfFileSize] pdf_file_size
         * @param {string} [updatedAt] updated_at
         * @param {string} [uploadUser] upload_user
         * @param {string} [uploadUserEmail] upload_user_email
         * @param {string} [uploadUserName] upload_user_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesList: async (agentCompany?: string, createdAt?: string, fileOwner?: string, fileSize?: string, id?: string, idIn?: string, mimeType?: string, name?: string, pdfFileSize?: string, updatedAt?: string, uploadUser?: string, uploadUserEmail?: string, uploadUserName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content_files/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (agentCompany !== undefined) {
                localVarQueryParameter['agent_company'] = agentCompany;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = createdAt;
            }

            if (fileOwner !== undefined) {
                localVarQueryParameter['file_owner'] = fileOwner;
            }

            if (fileSize !== undefined) {
                localVarQueryParameter['file_size'] = fileSize;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (idIn !== undefined) {
                localVarQueryParameter['id__in'] = idIn;
            }

            if (mimeType !== undefined) {
                localVarQueryParameter['mime_type'] = mimeType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (pdfFileSize !== undefined) {
                localVarQueryParameter['pdf_file_size'] = pdfFileSize;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = updatedAt;
            }

            if (uploadUser !== undefined) {
                localVarQueryParameter['upload_user'] = uploadUser;
            }

            if (uploadUserEmail !== undefined) {
                localVarQueryParameter['upload_user_email'] = uploadUserEmail;
            }

            if (uploadUserName !== undefined) {
                localVarQueryParameter['upload_user_name'] = uploadUserName;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} [name] 
         * @param {string} [fileOwner] 
         * @param {string} [agentCompany] 
         * @param {any} [file] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesPartialUpdate: async (id: string, name?: string, fileOwner?: string, agentCompany?: string, file?: any, pdfFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contentFilesPartialUpdate.');
            }
            const localVarPath = `/api/content_files/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (fileOwner !== undefined) { 
                localVarFormParams.append('file_owner', fileOwner as any);
            }
    
            if (agentCompany !== undefined) { 
                localVarFormParams.append('agent_company', agentCompany as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (pdfFile !== undefined) { 
                localVarFormParams.append('pdf_file', pdfFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contentFilesRetrieve.');
            }
            const localVarPath = `/api/content_files/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesUpdate: async (id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contentFilesUpdate.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling contentFilesUpdate.');
            }
            // verify required parameter 'fileOwner' is not null or undefined
            if (fileOwner === null || fileOwner === undefined) {
                throw new RequiredError('fileOwner','Required parameter fileOwner was null or undefined when calling contentFilesUpdate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling contentFilesUpdate.');
            }
            const localVarPath = `/api/content_files/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (fileOwner !== undefined) { 
                localVarFormParams.append('file_owner', fileOwner as any);
            }
    
            if (agentCompany !== undefined) { 
                localVarFormParams.append('agent_company', agentCompany as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (pdfFile !== undefined) { 
                localVarFormParams.append('pdf_file', pdfFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesUploadPdfCreate: async (id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling contentFilesUploadPdfCreate.');
            }
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling contentFilesUploadPdfCreate.');
            }
            // verify required parameter 'fileOwner' is not null or undefined
            if (fileOwner === null || fileOwner === undefined) {
                throw new RequiredError('fileOwner','Required parameter fileOwner was null or undefined when calling contentFilesUploadPdfCreate.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling contentFilesUploadPdfCreate.');
            }
            const localVarPath = `/api/content_files/{id}/upload_pdf/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (fileOwner !== undefined) { 
                localVarFormParams.append('file_owner', fileOwner as any);
            }
    
            if (agentCompany !== undefined) { 
                localVarFormParams.append('agent_company', agentCompany as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (pdfFile !== undefined) { 
                localVarFormParams.append('pdf_file', pdfFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentFilesApi - functional programming interface
 * @export
 */
export const ContentFilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesCreate(name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesCreate(name, fileOwner, file, agentCompany, pdfFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesDestroy(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesDestroy(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [agentCompany] agent_company
         * @param {string} [createdAt] created_at
         * @param {string} [fileOwner] file_owner
         * @param {string} [fileSize] file_size
         * @param {string} [id] id
         * @param {string} [idIn] id__in
         * @param {string} [mimeType] mime_type
         * @param {string} [name] name
         * @param {string} [pdfFileSize] pdf_file_size
         * @param {string} [updatedAt] updated_at
         * @param {string} [uploadUser] upload_user
         * @param {string} [uploadUserEmail] upload_user_email
         * @param {string} [uploadUserName] upload_user_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesList(agentCompany?: string, createdAt?: string, fileOwner?: string, fileSize?: string, id?: string, idIn?: string, mimeType?: string, name?: string, pdfFileSize?: string, updatedAt?: string, uploadUser?: string, uploadUserEmail?: string, uploadUserName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentFile>>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesList(agentCompany, createdAt, fileOwner, fileSize, id, idIn, mimeType, name, pdfFileSize, updatedAt, uploadUser, uploadUserEmail, uploadUserName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} [name] 
         * @param {string} [fileOwner] 
         * @param {string} [agentCompany] 
         * @param {any} [file] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesPartialUpdate(id: string, name?: string, fileOwner?: string, agentCompany?: string, file?: any, pdfFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesPartialUpdate(id, name, fileOwner, agentCompany, file, pdfFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesUpdate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesUpdate(id, name, fileOwner, file, agentCompany, pdfFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentFilesUploadPdfCreate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentFile>> {
            const localVarAxiosArgs = await ContentFilesApiAxiosParamCreator(configuration).contentFilesUploadPdfCreate(id, name, fileOwner, file, agentCompany, pdfFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentFilesApi - factory interface
 * @export
 */
export const ContentFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesCreate(name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): AxiosPromise<ContentFile> {
            return ContentFilesApiFp(configuration).contentFilesCreate(name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesDestroy(id: string, options?: any): AxiosPromise<void> {
            return ContentFilesApiFp(configuration).contentFilesDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [agentCompany] agent_company
         * @param {string} [createdAt] created_at
         * @param {string} [fileOwner] file_owner
         * @param {string} [fileSize] file_size
         * @param {string} [id] id
         * @param {string} [idIn] id__in
         * @param {string} [mimeType] mime_type
         * @param {string} [name] name
         * @param {string} [pdfFileSize] pdf_file_size
         * @param {string} [updatedAt] updated_at
         * @param {string} [uploadUser] upload_user
         * @param {string} [uploadUserEmail] upload_user_email
         * @param {string} [uploadUserName] upload_user_name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesList(agentCompany?: string, createdAt?: string, fileOwner?: string, fileSize?: string, id?: string, idIn?: string, mimeType?: string, name?: string, pdfFileSize?: string, updatedAt?: string, uploadUser?: string, uploadUserEmail?: string, uploadUserName?: string, options?: any): AxiosPromise<Array<ContentFile>> {
            return ContentFilesApiFp(configuration).contentFilesList(agentCompany, createdAt, fileOwner, fileSize, id, idIn, mimeType, name, pdfFileSize, updatedAt, uploadUser, uploadUserEmail, uploadUserName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} [name] 
         * @param {string} [fileOwner] 
         * @param {string} [agentCompany] 
         * @param {any} [file] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesPartialUpdate(id: string, name?: string, fileOwner?: string, agentCompany?: string, file?: any, pdfFile?: any, options?: any): AxiosPromise<ContentFile> {
            return ContentFilesApiFp(configuration).contentFilesPartialUpdate(id, name, fileOwner, agentCompany, file, pdfFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesRetrieve(id: string, options?: any): AxiosPromise<ContentFile> {
            return ContentFilesApiFp(configuration).contentFilesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesUpdate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): AxiosPromise<ContentFile> {
            return ContentFilesApiFp(configuration).contentFilesUpdate(id, name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this content file.
         * @param {string} name 
         * @param {string} fileOwner 
         * @param {any} file 
         * @param {string} [agentCompany] 
         * @param {any} [pdfFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentFilesUploadPdfCreate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any): AxiosPromise<ContentFile> {
            return ContentFilesApiFp(configuration).contentFilesUploadPdfCreate(id, name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentFilesApi - object-oriented interface
 * @export
 * @class ContentFilesApi
 * @extends {BaseAPI}
 */
export class ContentFilesApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {string} fileOwner 
     * @param {any} file 
     * @param {string} [agentCompany] 
     * @param {any} [pdfFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesCreate(name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesCreate(name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this content file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesDestroy(id: string, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [agentCompany] agent_company
     * @param {string} [createdAt] created_at
     * @param {string} [fileOwner] file_owner
     * @param {string} [fileSize] file_size
     * @param {string} [id] id
     * @param {string} [idIn] id__in
     * @param {string} [mimeType] mime_type
     * @param {string} [name] name
     * @param {string} [pdfFileSize] pdf_file_size
     * @param {string} [updatedAt] updated_at
     * @param {string} [uploadUser] upload_user
     * @param {string} [uploadUserEmail] upload_user_email
     * @param {string} [uploadUserName] upload_user_name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesList(agentCompany?: string, createdAt?: string, fileOwner?: string, fileSize?: string, id?: string, idIn?: string, mimeType?: string, name?: string, pdfFileSize?: string, updatedAt?: string, uploadUser?: string, uploadUserEmail?: string, uploadUserName?: string, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesList(agentCompany, createdAt, fileOwner, fileSize, id, idIn, mimeType, name, pdfFileSize, updatedAt, uploadUser, uploadUserEmail, uploadUserName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this content file.
     * @param {string} [name] 
     * @param {string} [fileOwner] 
     * @param {string} [agentCompany] 
     * @param {any} [file] 
     * @param {any} [pdfFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesPartialUpdate(id: string, name?: string, fileOwner?: string, agentCompany?: string, file?: any, pdfFile?: any, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesPartialUpdate(id, name, fileOwner, agentCompany, file, pdfFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this content file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesRetrieve(id: string, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this content file.
     * @param {string} name 
     * @param {string} fileOwner 
     * @param {any} file 
     * @param {string} [agentCompany] 
     * @param {any} [pdfFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesUpdate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesUpdate(id, name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this content file.
     * @param {string} name 
     * @param {string} fileOwner 
     * @param {any} file 
     * @param {string} [agentCompany] 
     * @param {any} [pdfFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentFilesApi
     */
    public contentFilesUploadPdfCreate(id: string, name: string, fileOwner: string, file: any, agentCompany?: string, pdfFile?: any, options?: any) {
        return ContentFilesApiFp(this.configuration).contentFilesUploadPdfCreate(id, name, fileOwner, file, agentCompany, pdfFile, options).then((request) => request(this.axios, this.basePath));
    }
}
