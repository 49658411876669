/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AgentCompany } from '../model';
// @ts-ignore
import { AgentCompanyAdd } from '../model';
// @ts-ignore
import { AgentCompanyChangeBilling } from '../model';
// @ts-ignore
import { AgentCompanyDetail } from '../model';
// @ts-ignore
import { AgentCompanyRegister } from '../model';
// @ts-ignore
import { AgentUser } from '../model';
// @ts-ignore
import { AgentUserAdd } from '../model';
// @ts-ignore
import { AgentUserDelete } from '../model';
// @ts-ignore
import { ChangePayer } from '../model';
// @ts-ignore
import { PatchedAgentCompanyDetail } from '../model';
/**
 * AgentCompanyApi - axios parameter creator
 * @export
 */
export const AgentCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyActiveCreate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyActiveCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/active/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyActiveUserCreate: async (id: string, agentUser: AgentUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyActiveUserCreate.');
            }
            // verify required parameter 'agentUser' is not null or undefined
            if (agentUser === null || agentUser === undefined) {
                throw new RequiredError('agentUser','Required parameter agentUser was null or undefined when calling agentCompanyActiveUserCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/active_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentUser !== undefined ? agentUser : {})
                : (agentUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserAdd} agentUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyAddUserCreate: async (id: string, agentUserAdd: AgentUserAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyAddUserCreate.');
            }
            // verify required parameter 'agentUserAdd' is not null or undefined
            if (agentUserAdd === null || agentUserAdd === undefined) {
                throw new RequiredError('agentUserAdd','Required parameter agentUserAdd was null or undefined when calling agentCompanyAddUserCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/add_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentUserAdd !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentUserAdd !== undefined ? agentUserAdd : {})
                : (agentUserAdd || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {AgentCompanyChangeBilling} agentCompanyChangeBilling 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangeBillingCreate: async (eventId: string, agentCompanyChangeBilling: AgentCompanyChangeBilling, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling agentCompanyChangeBillingCreate.');
            }
            // verify required parameter 'agentCompanyChangeBilling' is not null or undefined
            if (agentCompanyChangeBilling === null || agentCompanyChangeBilling === undefined) {
                throw new RequiredError('agentCompanyChangeBilling','Required parameter agentCompanyChangeBilling was null or undefined when calling agentCompanyChangeBillingCreate.');
            }
            const localVarPath = `/api/agent_company/change_billing/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentCompanyChangeBilling !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentCompanyChangeBilling !== undefined ? agentCompanyChangeBilling : {})
                : (agentCompanyChangeBilling || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangeBillingRetrieve: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling agentCompanyChangeBillingRetrieve.');
            }
            const localVarPath = `/api/agent_company/change_billing/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {ChangePayer} changePayer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangePayerCreate: async (id: string, changePayer: ChangePayer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyChangePayerCreate.');
            }
            // verify required parameter 'changePayer' is not null or undefined
            if (changePayer === null || changePayer === undefined) {
                throw new RequiredError('changePayer','Required parameter changePayer was null or undefined when calling agentCompanyChangePayerCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/change_payer/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changePayer !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changePayer !== undefined ? changePayer : {})
                : (changePayer || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AgentCompanyAdd} agentCompanyAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyCreate: async (agentCompanyAdd: AgentCompanyAdd, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentCompanyAdd' is not null or undefined
            if (agentCompanyAdd === null || agentCompanyAdd === undefined) {
                throw new RequiredError('agentCompanyAdd','Required parameter agentCompanyAdd was null or undefined when calling agentCompanyCreate.');
            }
            const localVarPath = `/api/agent_company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentCompanyAdd !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentCompanyAdd !== undefined ? agentCompanyAdd : {})
                : (agentCompanyAdd || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserDelete} agentUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyDeleteUserCreate: async (id: string, agentUserDelete: AgentUserDelete, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyDeleteUserCreate.');
            }
            // verify required parameter 'agentUserDelete' is not null or undefined
            if (agentUserDelete === null || agentUserDelete === undefined) {
                throw new RequiredError('agentUserDelete','Required parameter agentUserDelete was null or undefined when calling agentCompanyDeleteUserCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/delete_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentUserDelete !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentUserDelete !== undefined ? agentUserDelete : {})
                : (agentUserDelete || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyDestroy: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyDestroy.');
            }
            const localVarPath = `/api/agent_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyFreezeCreate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyFreezeCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/freeze/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyFreezeUserCreate: async (id: string, agentUser: AgentUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyFreezeUserCreate.');
            }
            // verify required parameter 'agentUser' is not null or undefined
            if (agentUser === null || agentUser === undefined) {
                throw new RequiredError('agentUser','Required parameter agentUser was null or undefined when calling agentCompanyFreezeUserCreate.');
            }
            const localVarPath = `/api/agent_company/{id}/freeze_user/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentUser !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentUser !== undefined ? agentUser : {})
                : (agentUser || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/agent_company/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {PatchedAgentCompanyDetail} [patchedAgentCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyPartialUpdate: async (id: string, patchedAgentCompanyDetail?: PatchedAgentCompanyDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyPartialUpdate.');
            }
            const localVarPath = `/api/agent_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof patchedAgentCompanyDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(patchedAgentCompanyDetail !== undefined ? patchedAgentCompanyDetail : {})
                : (patchedAgentCompanyDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {AgentCompanyRegister} agentCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRegisterCreate: async (eventId: string, agentCompanyRegister: AgentCompanyRegister, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling agentCompanyRegisterCreate.');
            }
            // verify required parameter 'agentCompanyRegister' is not null or undefined
            if (agentCompanyRegister === null || agentCompanyRegister === undefined) {
                throw new RequiredError('agentCompanyRegister','Required parameter agentCompanyRegister was null or undefined when calling agentCompanyRegisterCreate.');
            }
            const localVarPath = `/api/agent_company/register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentCompanyRegister !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentCompanyRegister !== undefined ? agentCompanyRegister : {})
                : (agentCompanyRegister || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRegisterRetrieve: async (eventId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling agentCompanyRegisterRetrieve.');
            }
            const localVarPath = `/api/agent_company/register/{event_id}/`
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyRetrieve.');
            }
            const localVarPath = `/api/agent_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentCompanyDetail} agentCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyUpdate: async (id: string, agentCompanyDetail: AgentCompanyDetail, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling agentCompanyUpdate.');
            }
            // verify required parameter 'agentCompanyDetail' is not null or undefined
            if (agentCompanyDetail === null || agentCompanyDetail === undefined) {
                throw new RequiredError('agentCompanyDetail','Required parameter agentCompanyDetail was null or undefined when calling agentCompanyUpdate.');
            }
            const localVarPath = `/api/agent_company/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof agentCompanyDetail !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(agentCompanyDetail !== undefined ? agentCompanyDetail : {})
                : (agentCompanyDetail || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentCompanyApi - functional programming interface
 * @export
 */
export const AgentCompanyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyActiveCreate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyActiveCreate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyActiveUserCreate(id: string, agentUser: AgentUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentUser>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyActiveUserCreate(id, agentUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserAdd} agentUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyAddUserCreate(id: string, agentUserAdd: AgentUserAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentUserAdd>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyAddUserCreate(id, agentUserAdd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {AgentCompanyChangeBilling} agentCompanyChangeBilling 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyChangeBillingCreate(eventId: string, agentCompanyChangeBilling: AgentCompanyChangeBilling, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyChangeBilling>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyChangeBillingCreate(eventId, agentCompanyChangeBilling, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyChangeBillingRetrieve(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyChangeBilling>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyChangeBillingRetrieve(eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {ChangePayer} changePayer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyChangePayerCreate(id: string, changePayer: ChangePayer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePayer>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyChangePayerCreate(id, changePayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AgentCompanyAdd} agentCompanyAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyCreate(agentCompanyAdd: AgentCompanyAdd, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyAdd>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyCreate(agentCompanyAdd, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserDelete} agentUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyDeleteUserCreate(id: string, agentUserDelete: AgentUserDelete, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentUserDelete>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyDeleteUserCreate(id, agentUserDelete, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyDestroy(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyDestroy(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyFreezeCreate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyFreezeCreate(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyFreezeUserCreate(id: string, agentUser: AgentUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentUser>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyFreezeUserCreate(id, agentUser, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentCompany>>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {PatchedAgentCompanyDetail} [patchedAgentCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyPartialUpdate(id: string, patchedAgentCompanyDetail?: PatchedAgentCompanyDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyDetail>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyPartialUpdate(id, patchedAgentCompanyDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {AgentCompanyRegister} agentCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyRegisterCreate(eventId: string, agentCompanyRegister: AgentCompanyRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyRegister>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyRegisterCreate(eventId, agentCompanyRegister, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyRegisterRetrieve(eventId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyRegister>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyRegisterRetrieve(eventId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyDetail>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentCompanyDetail} agentCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentCompanyUpdate(id: string, agentCompanyDetail: AgentCompanyDetail, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCompanyDetail>> {
            const localVarAxiosArgs = await AgentCompanyApiAxiosParamCreator(configuration).agentCompanyUpdate(id, agentCompanyDetail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AgentCompanyApi - factory interface
 * @export
 */
export const AgentCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyActiveCreate(id: string, options?: any): AxiosPromise<void> {
            return AgentCompanyApiFp(configuration).agentCompanyActiveCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyActiveUserCreate(id: string, agentUser: AgentUser, options?: any): AxiosPromise<AgentUser> {
            return AgentCompanyApiFp(configuration).agentCompanyActiveUserCreate(id, agentUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserAdd} agentUserAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyAddUserCreate(id: string, agentUserAdd: AgentUserAdd, options?: any): AxiosPromise<AgentUserAdd> {
            return AgentCompanyApiFp(configuration).agentCompanyAddUserCreate(id, agentUserAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {AgentCompanyChangeBilling} agentCompanyChangeBilling 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangeBillingCreate(eventId: string, agentCompanyChangeBilling: AgentCompanyChangeBilling, options?: any): AxiosPromise<AgentCompanyChangeBilling> {
            return AgentCompanyApiFp(configuration).agentCompanyChangeBillingCreate(eventId, agentCompanyChangeBilling, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId Change Payer Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangeBillingRetrieve(eventId: string, options?: any): AxiosPromise<AgentCompanyChangeBilling> {
            return AgentCompanyApiFp(configuration).agentCompanyChangeBillingRetrieve(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {ChangePayer} changePayer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyChangePayerCreate(id: string, changePayer: ChangePayer, options?: any): AxiosPromise<ChangePayer> {
            return AgentCompanyApiFp(configuration).agentCompanyChangePayerCreate(id, changePayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AgentCompanyAdd} agentCompanyAdd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyCreate(agentCompanyAdd: AgentCompanyAdd, options?: any): AxiosPromise<AgentCompanyAdd> {
            return AgentCompanyApiFp(configuration).agentCompanyCreate(agentCompanyAdd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUserDelete} agentUserDelete 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyDeleteUserCreate(id: string, agentUserDelete: AgentUserDelete, options?: any): AxiosPromise<AgentUserDelete> {
            return AgentCompanyApiFp(configuration).agentCompanyDeleteUserCreate(id, agentUserDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyDestroy(id: string, options?: any): AxiosPromise<void> {
            return AgentCompanyApiFp(configuration).agentCompanyDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyFreezeCreate(id: string, options?: any): AxiosPromise<void> {
            return AgentCompanyApiFp(configuration).agentCompanyFreezeCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentUser} agentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyFreezeUserCreate(id: string, agentUser: AgentUser, options?: any): AxiosPromise<AgentUser> {
            return AgentCompanyApiFp(configuration).agentCompanyFreezeUserCreate(id, agentUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyList(options?: any): AxiosPromise<Array<AgentCompany>> {
            return AgentCompanyApiFp(configuration).agentCompanyList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {PatchedAgentCompanyDetail} [patchedAgentCompanyDetail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyPartialUpdate(id: string, patchedAgentCompanyDetail?: PatchedAgentCompanyDetail, options?: any): AxiosPromise<AgentCompanyDetail> {
            return AgentCompanyApiFp(configuration).agentCompanyPartialUpdate(id, patchedAgentCompanyDetail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {AgentCompanyRegister} agentCompanyRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRegisterCreate(eventId: string, agentCompanyRegister: AgentCompanyRegister, options?: any): AxiosPromise<AgentCompanyRegister> {
            return AgentCompanyApiFp(configuration).agentCompanyRegisterCreate(eventId, agentCompanyRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} eventId Register Event ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRegisterRetrieve(eventId: string, options?: any): AxiosPromise<AgentCompanyRegister> {
            return AgentCompanyApiFp(configuration).agentCompanyRegisterRetrieve(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyRetrieve(id: string, options?: any): AxiosPromise<AgentCompanyDetail> {
            return AgentCompanyApiFp(configuration).agentCompanyRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this agent company.
         * @param {AgentCompanyDetail} agentCompanyDetail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentCompanyUpdate(id: string, agentCompanyDetail: AgentCompanyDetail, options?: any): AxiosPromise<AgentCompanyDetail> {
            return AgentCompanyApiFp(configuration).agentCompanyUpdate(id, agentCompanyDetail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentCompanyApi - object-oriented interface
 * @export
 * @class AgentCompanyApi
 * @extends {BaseAPI}
 */
export class AgentCompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyActiveCreate(id: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyActiveCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {AgentUser} agentUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyActiveUserCreate(id: string, agentUser: AgentUser, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyActiveUserCreate(id, agentUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {AgentUserAdd} agentUserAdd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyAddUserCreate(id: string, agentUserAdd: AgentUserAdd, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyAddUserCreate(id, agentUserAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId Change Payer Event ID
     * @param {AgentCompanyChangeBilling} agentCompanyChangeBilling 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyChangeBillingCreate(eventId: string, agentCompanyChangeBilling: AgentCompanyChangeBilling, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyChangeBillingCreate(eventId, agentCompanyChangeBilling, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId Change Payer Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyChangeBillingRetrieve(eventId: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyChangeBillingRetrieve(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {ChangePayer} changePayer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyChangePayerCreate(id: string, changePayer: ChangePayer, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyChangePayerCreate(id, changePayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AgentCompanyAdd} agentCompanyAdd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyCreate(agentCompanyAdd: AgentCompanyAdd, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyCreate(agentCompanyAdd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {AgentUserDelete} agentUserDelete 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyDeleteUserCreate(id: string, agentUserDelete: AgentUserDelete, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyDeleteUserCreate(id, agentUserDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyDestroy(id: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyFreezeCreate(id: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyFreezeCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {AgentUser} agentUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyFreezeUserCreate(id: string, agentUser: AgentUser, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyFreezeUserCreate(id, agentUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyList(options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {PatchedAgentCompanyDetail} [patchedAgentCompanyDetail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyPartialUpdate(id: string, patchedAgentCompanyDetail?: PatchedAgentCompanyDetail, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyPartialUpdate(id, patchedAgentCompanyDetail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId Register Event ID
     * @param {AgentCompanyRegister} agentCompanyRegister 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyRegisterCreate(eventId: string, agentCompanyRegister: AgentCompanyRegister, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyRegisterCreate(eventId, agentCompanyRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} eventId Register Event ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyRegisterRetrieve(eventId: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyRegisterRetrieve(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyRetrieve(id: string, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this agent company.
     * @param {AgentCompanyDetail} agentCompanyDetail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentCompanyApi
     */
    public agentCompanyUpdate(id: string, agentCompanyDetail: AgentCompanyDetail, options?: any) {
        return AgentCompanyApiFp(this.configuration).agentCompanyUpdate(id, agentCompanyDetail, options).then((request) => request(this.axios, this.basePath));
    }
}
