/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MagazineMaster } from '../model';
/**
 * MagazineMasterApi - axios parameter creator
 * @export
 */
export const MagazineMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [circulation] circulation
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [period] period
         * @param {string} [search] A search term.
         * @param {string} [updatedAt] updated_at
         * @param {string} [yomigana] yomigana
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazineMasterList: async (circulation?: string, company?: string, createdAt?: string, distinct?: string, id?: string, name?: string, ordering?: string, period?: string, search?: string, updatedAt?: string, yomigana?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/magazine_master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (circulation !== undefined) {
                localVarQueryParameter['circulation'] = circulation;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = createdAt;
            }

            if (distinct !== undefined) {
                localVarQueryParameter['distinct'] = distinct;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = updatedAt;
            }

            if (yomigana !== undefined) {
                localVarQueryParameter['yomigana'] = yomigana;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this magazine master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazineMasterRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling magazineMasterRetrieve.');
            }
            const localVarPath = `/api/magazine_master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MagazineMasterApi - functional programming interface
 * @export
 */
export const MagazineMasterApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [circulation] circulation
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [period] period
         * @param {string} [search] A search term.
         * @param {string} [updatedAt] updated_at
         * @param {string} [yomigana] yomigana
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magazineMasterList(circulation?: string, company?: string, createdAt?: string, distinct?: string, id?: string, name?: string, ordering?: string, period?: string, search?: string, updatedAt?: string, yomigana?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MagazineMaster>>> {
            const localVarAxiosArgs = await MagazineMasterApiAxiosParamCreator(configuration).magazineMasterList(circulation, company, createdAt, distinct, id, name, ordering, period, search, updatedAt, yomigana, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this magazine master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async magazineMasterRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MagazineMaster>> {
            const localVarAxiosArgs = await MagazineMasterApiAxiosParamCreator(configuration).magazineMasterRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MagazineMasterApi - factory interface
 * @export
 */
export const MagazineMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [circulation] circulation
         * @param {string} [company] company
         * @param {string} [createdAt] created_at
         * @param {string} [distinct] Distinct QuerySet
         * @param {string} [id] id
         * @param {string} [name] name
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [period] period
         * @param {string} [search] A search term.
         * @param {string} [updatedAt] updated_at
         * @param {string} [yomigana] yomigana
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazineMasterList(circulation?: string, company?: string, createdAt?: string, distinct?: string, id?: string, name?: string, ordering?: string, period?: string, search?: string, updatedAt?: string, yomigana?: string, options?: any): AxiosPromise<Array<MagazineMaster>> {
            return MagazineMasterApiFp(configuration).magazineMasterList(circulation, company, createdAt, distinct, id, name, ordering, period, search, updatedAt, yomigana, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this magazine master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        magazineMasterRetrieve(id: string, options?: any): AxiosPromise<MagazineMaster> {
            return MagazineMasterApiFp(configuration).magazineMasterRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MagazineMasterApi - object-oriented interface
 * @export
 * @class MagazineMasterApi
 * @extends {BaseAPI}
 */
export class MagazineMasterApi extends BaseAPI {
    /**
     * 
     * @param {string} [circulation] circulation
     * @param {string} [company] company
     * @param {string} [createdAt] created_at
     * @param {string} [distinct] Distinct QuerySet
     * @param {string} [id] id
     * @param {string} [name] name
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [period] period
     * @param {string} [search] A search term.
     * @param {string} [updatedAt] updated_at
     * @param {string} [yomigana] yomigana
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagazineMasterApi
     */
    public magazineMasterList(circulation?: string, company?: string, createdAt?: string, distinct?: string, id?: string, name?: string, ordering?: string, period?: string, search?: string, updatedAt?: string, yomigana?: string, options?: any) {
        return MagazineMasterApiFp(this.configuration).magazineMasterList(circulation, company, createdAt, distinct, id, name, ordering, period, search, updatedAt, yomigana, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this magazine master.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MagazineMasterApi
     */
    public magazineMasterRetrieve(id: string, options?: any) {
        return MagazineMasterApiFp(this.configuration).magazineMasterRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}
