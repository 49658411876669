/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum EmailTypeEnum {
    _01Header = '01_header',
    _02Footer = '02_footer',
    _11PasswordReset = '11_password_reset',
    _12PasswordChangeDone = '12_password_change_done',
    _31SystemUserCreate = '31_system_user_create',
    _32SystemUserDelete = '32_system_user_delete',
    _33SystemUserUpdatePermission = '33_system_user_update_permission',
    _41ClientCompanyRegister = '41_client_company_register',
    _42ClientCompanyRegisterDoneToClient = '42_client_company_register_done_to_client',
    _43ClientCompanyRegisterDoneToAdlog = '43_client_company_register_done_to_adlog',
    _44ClientUserRegister = '44_client_user_register',
    _45ClientUserRegisterDone = '45_client_user_register_done',
    _46ClientUserDelete = '46_client_user_delete',
    _47ClientUserUpdatePermission = '47_client_user_update_permission',
    _48ClientCancellationToClient = '48_client_cancellation_to_client',
    _49ClientCancellationToAdlog = '49_client_cancellation_to_adlog',
    _51AgentCompanyRegisterClientPayNewUser = '51_agent_company_register_client_pay_new_user',
    _52AgentCompanyRegisterClientPayExistingUser = '52_agent_company_register_client_pay_existing_user',
    _53AgentCompanyRegisterClientPayDoneToClient = '53_agent_company_register_client_pay_done_to_client',
    _54AgentCompanyRegisterClientPayDoneToAdlog = '54_agent_company_register_client_pay_done_to_adlog',
    _61AgentCompanyRegisterAgentPay = '61_agent_company_register_agent_pay',
    _62AgentCompanyRegisterAgentPayDoneExistingUser = '62_agent_company_register_agent_pay_done_existing_user',
    _63AgentCompanyRegisterAgentPayDoneNewUser = '63_agent_company_register_agent_pay_done_new_user',
    _64AgentCompanyRegisterAgentPayDoneToClient = '64_agent_company_register_agent_pay_done_to_client',
    _65AgentCompanyRegisterAgentPayDoneToAdlog = '65_agent_company_register_agent_pay_done_to_adlog',
    _71AgentExistingUserAddDone = '71_agent_existing_user_add_done',
    _72AgentUserAdd = '72_agent_user_add',
    _73AgentUserAddDoneToAgent = '73_agent_user_add_done_to_agent',
    _74AgentUserAddDoneToClient = '74_agent_user_add_done_to_client',
    _75AgentUserDelete = '75_agent_user_delete',
    _81ChangePayerToClient = '81_change_payer_to_client',
    _82ChangePayerToAgentRequest = '82_change_payer_to_agent_request',
    _83ChangePayerToAgentDoneToPayer = '83_change_payer_to_agent_done_to_payer',
    _84ChangePayerToAgentDoneToClient = '84_change_payer_to_agent_done_to_client',
    _85ChangePayerToAgentDoneToAdlog = '85_change_payer_to_agent_done_to_adlog'
}



