/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Campaign } from '../model';
// @ts-ignore
import { CampaignPermission } from '../model';
// @ts-ignore
import { Eval } from '../model';
// @ts-ignore
import { MagazineMediaFile } from '../model';
// @ts-ignore
import { MagazineReportFile } from '../model';
// @ts-ignore
import { NewspaperMediaFile } from '../model';
// @ts-ignore
import { NewspaperReportFile } from '../model';
// @ts-ignore
import { PatchedCampaign } from '../model';
// @ts-ignore
import { RadioSpotReportFile } from '../model';
// @ts-ignore
import { RadioTimeMediaFile } from '../model';
// @ts-ignore
import { RadioTimeReportFile } from '../model';
// @ts-ignore
import { TVSpotReportFile } from '../model';
// @ts-ignore
import { TVTimeMediaFile } from '../model';
// @ts-ignore
import { TVTimeReportFile } from '../model';
// @ts-ignore
import { TrafficMediaFile } from '../model';
// @ts-ignore
import { TrafficReportFile } from '../model';
// @ts-ignore
import { WebMediaFile } from '../model';
// @ts-ignore
import { WebReportFile } from '../model';
/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignCreate: async (campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling campaignCreate.');
            }
            const localVarPath = `/api/campaign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof campaign !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(campaign !== undefined ? campaign : {})
                : (campaign || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDestroy: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignDestroy.');
            }
            const localVarPath = `/api/campaign/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineMediaFile} magazineMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMagazineMediaFilesCreate: async (id: string, magazineMediaFile: MagazineMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignMagazineMediaFilesCreate.');
            }
            // verify required parameter 'magazineMediaFile' is not null or undefined
            if (magazineMediaFile === null || magazineMediaFile === undefined) {
                throw new RequiredError('magazineMediaFile','Required parameter magazineMediaFile was null or undefined when calling campaignMagazineMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/magazine_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof magazineMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(magazineMediaFile !== undefined ? magazineMediaFile : {})
                : (magazineMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineReportFile} magazineReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMagazineReportFilesCreate: async (id: string, magazineReportFile: MagazineReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignMagazineReportFilesCreate.');
            }
            // verify required parameter 'magazineReportFile' is not null or undefined
            if (magazineReportFile === null || magazineReportFile === undefined) {
                throw new RequiredError('magazineReportFile','Required parameter magazineReportFile was null or undefined when calling campaignMagazineReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/magazine_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof magazineReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(magazineReportFile !== undefined ? magazineReportFile : {})
                : (magazineReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperMediaFile} newspaperMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignNewspaperMediaFilesCreate: async (id: string, newspaperMediaFile: NewspaperMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignNewspaperMediaFilesCreate.');
            }
            // verify required parameter 'newspaperMediaFile' is not null or undefined
            if (newspaperMediaFile === null || newspaperMediaFile === undefined) {
                throw new RequiredError('newspaperMediaFile','Required parameter newspaperMediaFile was null or undefined when calling campaignNewspaperMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/newspaper_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newspaperMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newspaperMediaFile !== undefined ? newspaperMediaFile : {})
                : (newspaperMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperReportFile} newspaperReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignNewspaperReportFilesCreate: async (id: string, newspaperReportFile: NewspaperReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignNewspaperReportFilesCreate.');
            }
            // verify required parameter 'newspaperReportFile' is not null or undefined
            if (newspaperReportFile === null || newspaperReportFile === undefined) {
                throw new RequiredError('newspaperReportFile','Required parameter newspaperReportFile was null or undefined when calling campaignNewspaperReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/newspaper_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof newspaperReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(newspaperReportFile !== undefined ? newspaperReportFile : {})
                : (newspaperReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {PatchedCampaign} [patchedCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPartialUpdate: async (id: string, patchedCampaign?: PatchedCampaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignPartialUpdate.');
            }
            const localVarPath = `/api/campaign/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof patchedCampaign !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(patchedCampaign !== undefined ? patchedCampaign : {})
                : (patchedCampaign || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {CampaignPermission} [campaignPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPermissionCreate: async (id: string, campaignPermission?: CampaignPermission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignPermissionCreate.');
            }
            const localVarPath = `/api/campaign/{id}/permission/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof campaignPermission !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(campaignPermission !== undefined ? campaignPermission : {})
                : (campaignPermission || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {string} [company] Agent Company ID
         * @param {string} [user] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPermissionRetrieve: async (id: string, company?: string, user?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignPermissionRetrieve.');
            }
            const localVarPath = `/api/campaign/{id}/permission/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Eval} eval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPresentationEvalCreate: async (id: string, evals: Eval, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignPresentationEvalCreate.');
            }
            // verify required parameter 'eval' is not null or undefined
            if (evals === null || evals === undefined) {
                throw new RequiredError('eval','Required parameter eval was null or undefined when calling campaignPresentationEvalCreate.');
            }
            const localVarPath = `/api/campaign/{id}/presentation_eval/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof evals !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(evals !== undefined ? evals : {})
                : (evals || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioSpotReportFile} radioSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiospotReportFilesCreate: async (id: string, radioSpotReportFile: RadioSpotReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignRadiospotReportFilesCreate.');
            }
            // verify required parameter 'radioSpotReportFile' is not null or undefined
            if (radioSpotReportFile === null || radioSpotReportFile === undefined) {
                throw new RequiredError('radioSpotReportFile','Required parameter radioSpotReportFile was null or undefined when calling campaignRadiospotReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/radiospot_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof radioSpotReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(radioSpotReportFile !== undefined ? radioSpotReportFile : {})
                : (radioSpotReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeMediaFile} radioTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiotimeMediaFilesCreate: async (id: string, radioTimeMediaFile: RadioTimeMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignRadiotimeMediaFilesCreate.');
            }
            // verify required parameter 'radioTimeMediaFile' is not null or undefined
            if (radioTimeMediaFile === null || radioTimeMediaFile === undefined) {
                throw new RequiredError('radioTimeMediaFile','Required parameter radioTimeMediaFile was null or undefined when calling campaignRadiotimeMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/radiotime_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof radioTimeMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(radioTimeMediaFile !== undefined ? radioTimeMediaFile : {})
                : (radioTimeMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeReportFile} radioTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiotimeReportFilesCreate: async (id: string, radioTimeReportFile: RadioTimeReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignRadiotimeReportFilesCreate.');
            }
            // verify required parameter 'radioTimeReportFile' is not null or undefined
            if (radioTimeReportFile === null || radioTimeReportFile === undefined) {
                throw new RequiredError('radioTimeReportFile','Required parameter radioTimeReportFile was null or undefined when calling campaignRadiotimeReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/radiotime_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof radioTimeReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(radioTimeReportFile !== undefined ? radioTimeReportFile : {})
                : (radioTimeReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRetrieve: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignRetrieve.');
            }
            const localVarPath = `/api/campaign/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficMediaFile} trafficMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTrafficMediaFilesCreate: async (id: string, trafficMediaFile: TrafficMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignTrafficMediaFilesCreate.');
            }
            // verify required parameter 'trafficMediaFile' is not null or undefined
            if (trafficMediaFile === null || trafficMediaFile === undefined) {
                throw new RequiredError('trafficMediaFile','Required parameter trafficMediaFile was null or undefined when calling campaignTrafficMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/traffic_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trafficMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trafficMediaFile !== undefined ? trafficMediaFile : {})
                : (trafficMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficReportFile} trafficReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTrafficReportFilesCreate: async (id: string, trafficReportFile: TrafficReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignTrafficReportFilesCreate.');
            }
            // verify required parameter 'trafficReportFile' is not null or undefined
            if (trafficReportFile === null || trafficReportFile === undefined) {
                throw new RequiredError('trafficReportFile','Required parameter trafficReportFile was null or undefined when calling campaignTrafficReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/traffic_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trafficReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trafficReportFile !== undefined ? trafficReportFile : {})
                : (trafficReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVSpotReportFile} tVSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvspotReportFilesCreate: async (id: string, tVSpotReportFile: TVSpotReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignTvspotReportFilesCreate.');
            }
            // verify required parameter 'tVSpotReportFile' is not null or undefined
            if (tVSpotReportFile === null || tVSpotReportFile === undefined) {
                throw new RequiredError('tVSpotReportFile','Required parameter tVSpotReportFile was null or undefined when calling campaignTvspotReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/tvspot_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tVSpotReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tVSpotReportFile !== undefined ? tVSpotReportFile : {})
                : (tVSpotReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeMediaFile} tVTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvtimeMediaFilesCreate: async (id: string, tVTimeMediaFile: TVTimeMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignTvtimeMediaFilesCreate.');
            }
            // verify required parameter 'tVTimeMediaFile' is not null or undefined
            if (tVTimeMediaFile === null || tVTimeMediaFile === undefined) {
                throw new RequiredError('tVTimeMediaFile','Required parameter tVTimeMediaFile was null or undefined when calling campaignTvtimeMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/tvtime_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tVTimeMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tVTimeMediaFile !== undefined ? tVTimeMediaFile : {})
                : (tVTimeMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeReportFile} tVTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvtimeReportFilesCreate: async (id: string, tVTimeReportFile: TVTimeReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignTvtimeReportFilesCreate.');
            }
            // verify required parameter 'tVTimeReportFile' is not null or undefined
            if (tVTimeReportFile === null || tVTimeReportFile === undefined) {
                throw new RequiredError('tVTimeReportFile','Required parameter tVTimeReportFile was null or undefined when calling campaignTvtimeReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/tvtime_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tVTimeReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tVTimeReportFile !== undefined ? tVTimeReportFile : {})
                : (tVTimeReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignUpdate: async (id: string, campaign: Campaign, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignUpdate.');
            }
            // verify required parameter 'campaign' is not null or undefined
            if (campaign === null || campaign === undefined) {
                throw new RequiredError('campaign','Required parameter campaign was null or undefined when calling campaignUpdate.');
            }
            const localVarPath = `/api/campaign/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof campaign !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(campaign !== undefined ? campaign : {})
                : (campaign || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebMediaFile} webMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWebMediaFilesCreate: async (id: string, webMediaFile: WebMediaFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignWebMediaFilesCreate.');
            }
            // verify required parameter 'webMediaFile' is not null or undefined
            if (webMediaFile === null || webMediaFile === undefined) {
                throw new RequiredError('webMediaFile','Required parameter webMediaFile was null or undefined when calling campaignWebMediaFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/web_media_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof webMediaFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(webMediaFile !== undefined ? webMediaFile : {})
                : (webMediaFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebReportFile} webReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWebReportFilesCreate: async (id: string, webReportFile: WebReportFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling campaignWebReportFilesCreate.');
            }
            // verify required parameter 'webReportFile' is not null or undefined
            if (webReportFile === null || webReportFile === undefined) {
                throw new RequiredError('webReportFile','Required parameter webReportFile was null or undefined when calling campaignWebReportFilesCreate.');
            }
            const localVarPath = `/api/campaign/{id}/web_report_files/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof webReportFile !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(webReportFile !== undefined ? webReportFile : {})
                : (webReportFile || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignCreate(campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignCreate(campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignDestroy(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignDestroy(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Campaign>>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignList(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineMediaFile} magazineMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignMagazineMediaFilesCreate(id: string, magazineMediaFile: MagazineMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MagazineMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignMagazineMediaFilesCreate(id, magazineMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineReportFile} magazineReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignMagazineReportFilesCreate(id: string, magazineReportFile: MagazineReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MagazineReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignMagazineReportFilesCreate(id, magazineReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperMediaFile} newspaperMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignNewspaperMediaFilesCreate(id: string, newspaperMediaFile: NewspaperMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewspaperMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignNewspaperMediaFilesCreate(id, newspaperMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperReportFile} newspaperReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignNewspaperReportFilesCreate(id: string, newspaperReportFile: NewspaperReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewspaperReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignNewspaperReportFilesCreate(id, newspaperReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {PatchedCampaign} [patchedCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPartialUpdate(id: string, patchedCampaign?: PatchedCampaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignPartialUpdate(id, patchedCampaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {CampaignPermission} [campaignPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPermissionCreate(id: string, campaignPermission?: CampaignPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignPermission>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignPermissionCreate(id, campaignPermission, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {string} [company] Agent Company ID
         * @param {string} [user] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPermissionRetrieve(id: string, company?: string, user?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignPermission>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignPermissionRetrieve(id, company, user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Eval} eval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPresentationEvalCreate(id: string, evals: Eval, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Eval>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignPresentationEvalCreate(id, evals, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioSpotReportFile} radioSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRadiospotReportFilesCreate(id: string, radioSpotReportFile: RadioSpotReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadioSpotReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignRadiospotReportFilesCreate(id, radioSpotReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeMediaFile} radioTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRadiotimeMediaFilesCreate(id: string, radioTimeMediaFile: RadioTimeMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadioTimeMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignRadiotimeMediaFilesCreate(id, radioTimeMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeReportFile} radioTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRadiotimeReportFilesCreate(id: string, radioTimeReportFile: RadioTimeReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RadioTimeReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignRadiotimeReportFilesCreate(id, radioTimeReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRetrieve(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignRetrieve(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficMediaFile} trafficMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTrafficMediaFilesCreate(id: string, trafficMediaFile: TrafficMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignTrafficMediaFilesCreate(id, trafficMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficReportFile} trafficReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTrafficReportFilesCreate(id: string, trafficReportFile: TrafficReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrafficReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignTrafficReportFilesCreate(id, trafficReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVSpotReportFile} tVSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTvspotReportFilesCreate(id: string, tVSpotReportFile: TVSpotReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TVSpotReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignTvspotReportFilesCreate(id, tVSpotReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeMediaFile} tVTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTvtimeMediaFilesCreate(id: string, tVTimeMediaFile: TVTimeMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TVTimeMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignTvtimeMediaFilesCreate(id, tVTimeMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeReportFile} tVTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTvtimeReportFilesCreate(id: string, tVTimeReportFile: TVTimeReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TVTimeReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignTvtimeReportFilesCreate(id, tVTimeReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignUpdate(id: string, campaign: Campaign, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignUpdate(id, campaign, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebMediaFile} webMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignWebMediaFilesCreate(id: string, webMediaFile: WebMediaFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebMediaFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignWebMediaFilesCreate(id, webMediaFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebReportFile} webReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignWebReportFilesCreate(id: string, webReportFile: WebReportFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebReportFile>> {
            const localVarAxiosArgs = await CampaignApiAxiosParamCreator(configuration).campaignWebReportFilesCreate(id, webReportFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignCreate(campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignCreate(campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDestroy(id: string, options?: any): AxiosPromise<void> {
            return CampaignApiFp(configuration).campaignDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignList(options?: any): AxiosPromise<Array<Campaign>> {
            return CampaignApiFp(configuration).campaignList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineMediaFile} magazineMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMagazineMediaFilesCreate(id: string, magazineMediaFile: MagazineMediaFile, options?: any): AxiosPromise<MagazineMediaFile> {
            return CampaignApiFp(configuration).campaignMagazineMediaFilesCreate(id, magazineMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {MagazineReportFile} magazineReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignMagazineReportFilesCreate(id: string, magazineReportFile: MagazineReportFile, options?: any): AxiosPromise<MagazineReportFile> {
            return CampaignApiFp(configuration).campaignMagazineReportFilesCreate(id, magazineReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperMediaFile} newspaperMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignNewspaperMediaFilesCreate(id: string, newspaperMediaFile: NewspaperMediaFile, options?: any): AxiosPromise<NewspaperMediaFile> {
            return CampaignApiFp(configuration).campaignNewspaperMediaFilesCreate(id, newspaperMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {NewspaperReportFile} newspaperReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignNewspaperReportFilesCreate(id: string, newspaperReportFile: NewspaperReportFile, options?: any): AxiosPromise<NewspaperReportFile> {
            return CampaignApiFp(configuration).campaignNewspaperReportFilesCreate(id, newspaperReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {PatchedCampaign} [patchedCampaign] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPartialUpdate(id: string, patchedCampaign?: PatchedCampaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignPartialUpdate(id, patchedCampaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {CampaignPermission} [campaignPermission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPermissionCreate(id: string, campaignPermission?: CampaignPermission, options?: any): AxiosPromise<CampaignPermission> {
            return CampaignApiFp(configuration).campaignPermissionCreate(id, campaignPermission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {string} [company] Agent Company ID
         * @param {string} [user] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPermissionRetrieve(id: string, company?: string, user?: string, options?: any): AxiosPromise<CampaignPermission> {
            return CampaignApiFp(configuration).campaignPermissionRetrieve(id, company, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Eval} eval 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPresentationEvalCreate(id: string, evals: Eval, options?: any): AxiosPromise<Eval> {
            return CampaignApiFp(configuration).campaignPresentationEvalCreate(id, evals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioSpotReportFile} radioSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiospotReportFilesCreate(id: string, radioSpotReportFile: RadioSpotReportFile, options?: any): AxiosPromise<RadioSpotReportFile> {
            return CampaignApiFp(configuration).campaignRadiospotReportFilesCreate(id, radioSpotReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeMediaFile} radioTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiotimeMediaFilesCreate(id: string, radioTimeMediaFile: RadioTimeMediaFile, options?: any): AxiosPromise<RadioTimeMediaFile> {
            return CampaignApiFp(configuration).campaignRadiotimeMediaFilesCreate(id, radioTimeMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {RadioTimeReportFile} radioTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRadiotimeReportFilesCreate(id: string, radioTimeReportFile: RadioTimeReportFile, options?: any): AxiosPromise<RadioTimeReportFile> {
            return CampaignApiFp(configuration).campaignRadiotimeReportFilesCreate(id, radioTimeReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRetrieve(id: string, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficMediaFile} trafficMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTrafficMediaFilesCreate(id: string, trafficMediaFile: TrafficMediaFile, options?: any): AxiosPromise<TrafficMediaFile> {
            return CampaignApiFp(configuration).campaignTrafficMediaFilesCreate(id, trafficMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TrafficReportFile} trafficReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTrafficReportFilesCreate(id: string, trafficReportFile: TrafficReportFile, options?: any): AxiosPromise<TrafficReportFile> {
            return CampaignApiFp(configuration).campaignTrafficReportFilesCreate(id, trafficReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVSpotReportFile} tVSpotReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvspotReportFilesCreate(id: string, tVSpotReportFile: TVSpotReportFile, options?: any): AxiosPromise<TVSpotReportFile> {
            return CampaignApiFp(configuration).campaignTvspotReportFilesCreate(id, tVSpotReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeMediaFile} tVTimeMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvtimeMediaFilesCreate(id: string, tVTimeMediaFile: TVTimeMediaFile, options?: any): AxiosPromise<TVTimeMediaFile> {
            return CampaignApiFp(configuration).campaignTvtimeMediaFilesCreate(id, tVTimeMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {TVTimeReportFile} tVTimeReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTvtimeReportFilesCreate(id: string, tVTimeReportFile: TVTimeReportFile, options?: any): AxiosPromise<TVTimeReportFile> {
            return CampaignApiFp(configuration).campaignTvtimeReportFilesCreate(id, tVTimeReportFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignUpdate(id: string, campaign: Campaign, options?: any): AxiosPromise<Campaign> {
            return CampaignApiFp(configuration).campaignUpdate(id, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebMediaFile} webMediaFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWebMediaFilesCreate(id: string, webMediaFile: WebMediaFile, options?: any): AxiosPromise<WebMediaFile> {
            return CampaignApiFp(configuration).campaignWebMediaFilesCreate(id, webMediaFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this campaign.
         * @param {WebReportFile} webReportFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWebReportFilesCreate(id: string, webReportFile: WebReportFile, options?: any): AxiosPromise<WebReportFile> {
            return CampaignApiFp(configuration).campaignWebReportFilesCreate(id, webReportFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignCreate(campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).campaignCreate(campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignDestroy(id: string, options?: any) {
        return CampaignApiFp(this.configuration).campaignDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignList(options?: any) {
        return CampaignApiFp(this.configuration).campaignList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {MagazineMediaFile} magazineMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignMagazineMediaFilesCreate(id: string, magazineMediaFile: MagazineMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignMagazineMediaFilesCreate(id, magazineMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {MagazineReportFile} magazineReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignMagazineReportFilesCreate(id: string, magazineReportFile: MagazineReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignMagazineReportFilesCreate(id, magazineReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {NewspaperMediaFile} newspaperMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignNewspaperMediaFilesCreate(id: string, newspaperMediaFile: NewspaperMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignNewspaperMediaFilesCreate(id, newspaperMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {NewspaperReportFile} newspaperReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignNewspaperReportFilesCreate(id: string, newspaperReportFile: NewspaperReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignNewspaperReportFilesCreate(id, newspaperReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {PatchedCampaign} [patchedCampaign] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignPartialUpdate(id: string, patchedCampaign?: PatchedCampaign, options?: any) {
        return CampaignApiFp(this.configuration).campaignPartialUpdate(id, patchedCampaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {CampaignPermission} [campaignPermission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignPermissionCreate(id: string, campaignPermission?: CampaignPermission, options?: any) {
        return CampaignApiFp(this.configuration).campaignPermissionCreate(id, campaignPermission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {string} [company] Agent Company ID
     * @param {string} [user] User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignPermissionRetrieve(id: string, company?: string, user?: string, options?: any) {
        return CampaignApiFp(this.configuration).campaignPermissionRetrieve(id, company, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {Eval} eval 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignPresentationEvalCreate(id: string, evals: Eval, options?: any) {
        return CampaignApiFp(this.configuration).campaignPresentationEvalCreate(id, evals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {RadioSpotReportFile} radioSpotReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRadiospotReportFilesCreate(id: string, radioSpotReportFile: RadioSpotReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignRadiospotReportFilesCreate(id, radioSpotReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {RadioTimeMediaFile} radioTimeMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRadiotimeMediaFilesCreate(id: string, radioTimeMediaFile: RadioTimeMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignRadiotimeMediaFilesCreate(id, radioTimeMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {RadioTimeReportFile} radioTimeReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRadiotimeReportFilesCreate(id: string, radioTimeReportFile: RadioTimeReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignRadiotimeReportFilesCreate(id, radioTimeReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRetrieve(id: string, options?: any) {
        return CampaignApiFp(this.configuration).campaignRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {TrafficMediaFile} trafficMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTrafficMediaFilesCreate(id: string, trafficMediaFile: TrafficMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignTrafficMediaFilesCreate(id, trafficMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {TrafficReportFile} trafficReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTrafficReportFilesCreate(id: string, trafficReportFile: TrafficReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignTrafficReportFilesCreate(id, trafficReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {TVSpotReportFile} tVSpotReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTvspotReportFilesCreate(id: string, tVSpotReportFile: TVSpotReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignTvspotReportFilesCreate(id, tVSpotReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {TVTimeMediaFile} tVTimeMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTvtimeMediaFilesCreate(id: string, tVTimeMediaFile: TVTimeMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignTvtimeMediaFilesCreate(id, tVTimeMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {TVTimeReportFile} tVTimeReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTvtimeReportFilesCreate(id: string, tVTimeReportFile: TVTimeReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignTvtimeReportFilesCreate(id, tVTimeReportFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {Campaign} campaign 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignUpdate(id: string, campaign: Campaign, options?: any) {
        return CampaignApiFp(this.configuration).campaignUpdate(id, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {WebMediaFile} webMediaFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignWebMediaFilesCreate(id: string, webMediaFile: WebMediaFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignWebMediaFilesCreate(id, webMediaFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this campaign.
     * @param {WebReportFile} webReportFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignWebReportFilesCreate(id: string, webReportFile: WebReportFile, options?: any) {
        return CampaignApiFp(this.configuration).campaignWebReportFilesCreate(id, webReportFile, options).then((request) => request(this.axios, this.basePath));
    }
}
