/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientCancellation } from '../model';
/**
 * ClientCancelApi - axios parameter creator
 * @export
 */
export const ClientCancelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 広告主の解約申請API  広告管理者のみ利用可能 POSTのみ可能  Request Example: ```json {     \"owner_name\": \"佐藤太郎\",     \"writer_name\": \"佐藤太郎\",     \"writer_department\": \"広報部\",     \"writer_tel\": \"0123456789\",     \"writer_email\": \"client-a-owner@example.com\",     \"desired_month\": 6,     \"reasons\": [         \"思っていた機能と違ったから\",         \"料金が高いから\"     ],     \"reason_detail\": \"XXXがよくなかった。\" } ```
         * @param {ClientCancellation} clientCancellation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCancelCreate: async (clientCancellation: ClientCancellation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCancellation' is not null or undefined
            if (clientCancellation === null || clientCancellation === undefined) {
                throw new RequiredError('clientCancellation','Required parameter clientCancellation was null or undefined when calling clientCancelCreate.');
            }
            const localVarPath = `/api/client_cancel/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof clientCancellation !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(clientCancellation !== undefined ? clientCancellation : {})
                : (clientCancellation || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientCancelApi - functional programming interface
 * @export
 */
export const ClientCancelApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 広告主の解約申請API  広告管理者のみ利用可能 POSTのみ可能  Request Example: ```json {     \"owner_name\": \"佐藤太郎\",     \"writer_name\": \"佐藤太郎\",     \"writer_department\": \"広報部\",     \"writer_tel\": \"0123456789\",     \"writer_email\": \"client-a-owner@example.com\",     \"desired_month\": 6,     \"reasons\": [         \"思っていた機能と違ったから\",         \"料金が高いから\"     ],     \"reason_detail\": \"XXXがよくなかった。\" } ```
         * @param {ClientCancellation} clientCancellation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCancelCreate(clientCancellation: ClientCancellation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCancellation>> {
            const localVarAxiosArgs = await ClientCancelApiAxiosParamCreator(configuration).clientCancelCreate(clientCancellation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientCancelApi - factory interface
 * @export
 */
export const ClientCancelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 広告主の解約申請API  広告管理者のみ利用可能 POSTのみ可能  Request Example: ```json {     \"owner_name\": \"佐藤太郎\",     \"writer_name\": \"佐藤太郎\",     \"writer_department\": \"広報部\",     \"writer_tel\": \"0123456789\",     \"writer_email\": \"client-a-owner@example.com\",     \"desired_month\": 6,     \"reasons\": [         \"思っていた機能と違ったから\",         \"料金が高いから\"     ],     \"reason_detail\": \"XXXがよくなかった。\" } ```
         * @param {ClientCancellation} clientCancellation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCancelCreate(clientCancellation: ClientCancellation, options?: any): AxiosPromise<ClientCancellation> {
            return ClientCancelApiFp(configuration).clientCancelCreate(clientCancellation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientCancelApi - object-oriented interface
 * @export
 * @class ClientCancelApi
 * @extends {BaseAPI}
 */
export class ClientCancelApi extends BaseAPI {
    /**
     * 広告主の解約申請API  広告管理者のみ利用可能 POSTのみ可能  Request Example: ```json {     \"owner_name\": \"佐藤太郎\",     \"writer_name\": \"佐藤太郎\",     \"writer_department\": \"広報部\",     \"writer_tel\": \"0123456789\",     \"writer_email\": \"client-a-owner@example.com\",     \"desired_month\": 6,     \"reasons\": [         \"思っていた機能と違ったから\",         \"料金が高いから\"     ],     \"reason_detail\": \"XXXがよくなかった。\" } ```
     * @param {ClientCancellation} clientCancellation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientCancelApi
     */
    public clientCancelCreate(clientCancellation: ClientCancellation, options?: any) {
        return ClientCancelApiFp(this.configuration).clientCancelCreate(clientCancellation, options).then((request) => request(this.axios, this.basePath));
    }
}
