/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum CodenameEnum {
    AccessCampaign = 'access_campaign',
    ViewOrientationWithCompetition = 'view_orientation_with_competition',
    ViewOrientationWithoutCompetition = 'view_orientation_without_competition',
    ViewConcept = 'view_concept',
    ChangeConcept = 'change_concept',
    ViewStrategy = 'view_strategy',
    ChangeStrategy = 'change_strategy',
    ViewKeyVisual = 'view_key_visual',
    ChangeKeyVisual = 'change_key_visual',
    ViewPurpose = 'view_purpose',
    ComparePurpose = 'compare_purpose',
    ViewMainTarget = 'view_main_target',
    CompareMainTarget = 'compare_main_target',
    ViewSubTarget = 'view_sub_target',
    CompareSubTarget = 'compare_sub_target',
    ViewCost = 'view_cost',
    CompareCost = 'compare_cost',
    ViewMediaCost = 'view_media_cost',
    CompareMediaCost = 'compare_media_cost',
    ViewKpi = 'view_kpi',
    CompareKpi = 'compare_kpi',
    ViewPromotion = 'view_promotion',
    ChangePromotion = 'change_promotion',
    ComparePromotion = 'compare_promotion',
    ViewSns = 'view_sns',
    CompareSns = 'compare_sns',
    ViewPr = 'view_pr',
    ComparePr = 'compare_pr',
    ViewImprovement = 'view_improvement',
    ViewWithReviewTvspot = 'view_with_review_tvspot',
    ViewWithoutReviewTvspot = 'view_without_review_tvspot',
    ChangeTvspot = 'change_tvspot',
    ViewWithReviewTvtime = 'view_with_review_tvtime',
    ViewWithoutReviewTvtime = 'view_without_review_tvtime',
    ChangeTvtime = 'change_tvtime',
    ViewWithReviewRadiospot = 'view_with_review_radiospot',
    ViewWithoutReviewRadiospot = 'view_without_review_radiospot',
    ChangeRadiospot = 'change_radiospot',
    ViewWithReviewRadiotime = 'view_with_review_radiotime',
    ViewWithoutReviewRadiotime = 'view_without_review_radiotime',
    ChangeRadiotime = 'change_radiotime',
    ViewWithReviewNewspaper = 'view_with_review_newspaper',
    ViewWithoutReviewNewspaper = 'view_without_review_newspaper',
    ChangeNewspaper = 'change_newspaper',
    ViewWithReviewMagazine = 'view_with_review_magazine',
    ViewWithoutReviewMagazine = 'view_without_review_magazine',
    ChangeMagazine = 'change_magazine',
    ViewWithReviewTraffic = 'view_with_review_traffic',
    ViewWithoutReviewTraffic = 'view_without_review_traffic',
    ChangeTraffic = 'change_traffic',
    ViewWithReviewWeb = 'view_with_review_web',
    ViewWithoutReviewWeb = 'view_without_review_web',
    ChangeWeb = 'change_web',
    ViewWithReviewTopic = 'view_with_review_topic'
}



